import React, { useEffect } from 'react';

import Swiper from 'swiper';
import useStorage from '../../hooks/useStorage';

const Gallery = ({ picturesFolder, name }) => {

  const [getImages, { response }] = useStorage(picturesFolder);

  useEffect(() => {
    new Swiper ('.swiper-container-gallery', {
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        1150: {
          slidesPerView: 2,
          slidesPerColumn: 1,
        },
        668: {
          slidesPerView: 1,
          slidesPerColumn: 1,
        },
        320: {
          slidesPerView: 1,
          slidesPerColumn: 1,
        }
      }
    });
  }, [response]);

  useEffect(() => {
    if (picturesFolder) getImages();
  }, [picturesFolder]);

  return (
    <section
      class="section has-dots no-padding-top no-padding-bottom"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-bg.svg')})` }}>
      <div class="container">
        <div class="swiper-container swiper-container-gallery">
          <div class="swiper-wrapper">
            {response.map((pic, index) => (
              <div class="swiper-slide" key={index}>
                <figure
                  class="image is-gallery-item"
                  style={{ backgroundImage: `url(${pic.trim()})` }}>
                  <img src={pic.trim()} alt={name + ' imagem ' + (index + 1)} />
                </figure>
              </div>
            ))}
          </div>
          <div class="swiper-pagination" />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
import React from 'react';
import { Link } from 'gatsby';

const Cta = (props) => (
  <section className="section has-title-overlaid">
    <span className="title-overlaid">Escolha essa ONG</span>
    <div className="container">
      <div className="wrapper is-flex">
        <h1 className="title is-xlarge is-uppercase is-alternative is-700">Escolha essa ONG</h1>
        <Link to={`cadastro?uid=${props.id}`} state={{ institution: props }} className="button">
          <span>Selecionar ONG</span>
        </Link>
      </div>
    </div>
  </section>
);

export default Cta;
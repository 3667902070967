import React, { useEffect } from 'react';

import useStorage from '../../hooks/useStorage';

const Hero = ({ picturesFolder }) => {

  const [getImages, { response }] = useStorage(picturesFolder);

  useEffect(() => {
    if (picturesFolder) getImages();
  }, [picturesFolder]);

  return (
    <section
      class="hero is-inner"
      style={{ backgroundImage: `url(${response[0]})` }}>
      <div class="hero-body">
        <div class="container" />
      </div>
    </section>
  );
};

export default Hero;